console.log("hello");
var i = 0;
var j = 1;
var ringclass = "init";
var cir = document.body.querySelector(ringclass);

//les fonctions pour le changement de couleur 
function addColor (){
    console.log("yes");
    ringclass = ".circle"+j+"__"+(i*10);
    cir =document.querySelector(ringclass);
    cir.classList.remove("pourceWhite");
    cir.classList.add("pource");
    console.log(".circle"+j+"__"+(i*10));

}
function removeColor(){
    ringclass = ".circle"+j+"__" + (i*10);
    cir =document.querySelector(ringclass);
    cir.classList.remove("pource");
    cir.classList.add("pourceWhite");
    console.log(".circle"+j+"__"+(i*10)+1);
}

// pour la question 1 
var question1 = [50, 33, 20, 10]; 
let q1a = document.querySelector("#q1a");
let q1b = document.querySelector("#q1b");
let q1c = document.querySelector("#q1c");
let q1d = document.querySelector("#q1d");

function listBtn(){
        q1b.classList.remove("active");
        q1a.classList.remove("active");
        q1c.classList.remove("active");
        q1d.classList.remove("active");
}

q1a.addEventListener("click", (e)=>{
    listBtn();
    j=1;
    document.querySelector(".data1").innerHTML =49;
    for( i=1; i<=10; i++){
        if(question1[0] == 0){
            console.log("0");
        }else if(question1[0] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
        q1a.classList.add("active");
    }
});
q1b.addEventListener("click", (e)=>{
    listBtn();
    j=1;
    document.querySelector(".data1").innerHTML =33;
    for( i=1; i<=10; i++){
        if(question1[1] == 0){
            console.log("0");
        }else if(question1[1] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
        q1b.classList.add("active");
    }
});
q1c.addEventListener("click", (e)=>{
    listBtn();
    j=1;
    document.querySelector(".data1").innerHTML =14;
    for( i=1; i<=10; i++){
        if(question1[2] == 0){
            console.log("0");
        }else if(question1[2] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
        q1c.classList.add("active");
    }
});
q1d.addEventListener("click", (e)=>{
    listBtn();
    j=1;
    document.querySelector(".data1").innerHTML =4;
    for( i=1; i<=10; i++){
        if(question1[3] == 0){
            console.log("0");
        }else if(question1[3] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
        q1d.classList.add("active");
    }
});

// pour la question 2
var question1 = [50, 30, 0]; 
let q2a = document.querySelector("#q2a");
let q2b = document.querySelector("#q2b");
let q2c = document.querySelector("#q2c");
function listBtn2(){
    q2b.classList.remove("active");
    q2a.classList.remove("active");
    q2c.classList.remove("active");
}
q2a.addEventListener("click", (e)=>{
    listBtn2();
    j = 2;
    document.querySelector(".data2").innerHTML =47;
    for( i=1; i<=10; i++){
        if(question1[0] == 0){
            console.log("0");
        }else if(question1[0] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
        q2a.classList.add("active");
    }
});
q2b.addEventListener("click", (e)=>{
    listBtn2();
    j=2;
    document.querySelector(".data2").innerHTML =30;
    for( i=1; i<=10; i++){
        if(question1[1] == 0){
            console.log("0");
        }else if(question1[1] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
        q2b.classList.add("active");
    }
});
q2c.addEventListener("click", (e)=>{
    listBtn2();
    j=2;
    document.querySelector(".data2").innerHTML =0;
    for( i=1; i<=10; i++){
        if(question1[2] == 0){
            console.log("0");
        }else if(question1[2] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
        q2c.classList.add("active");
    }
});

// pour la question 3
var question1 = [70, 30]; 
let q3a = document.querySelector("#q3a");
let q3b = document.querySelector("#q3b");
function listBtn3(){
    q3b.classList.remove("active");
    q3a.classList.remove("active");
}
q3a.addEventListener("click", (e)=>{
    listBtn3();
    j = 3;
    document.querySelector(".data3").innerHTML =67;
    for( i=1; i<=10; i++){
        if(question1[0] == 0){
            console.log("0");
        }else if(question1[0] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
        q3a.classList.add("active");
    }
});
q3b.addEventListener("click", (e)=>{
    listBtn3();
    j=3;
    document.querySelector(".data3").innerHTML =33;
    for( i=1; i<=10; i++){
        if(question1[1] == 0){
            console.log("0");
        }else if(question1[1] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
        q3b.classList.add("active");
    }
});

// pour la question 4
var question1 = [50, 30, 13, 10]; 
let q4a = document.querySelector("#q4a");
let q4b = document.querySelector("#q4b");
let q4c = document.querySelector("#q4c");
let q4d = document.querySelector("#q4d");
function listBtn4(){
    q4b.classList.remove("active");
    q4a.classList.remove("active");
    q4c.classList.remove("active");
    q4d.classList.remove("active");
}
q4a.addEventListener("click", (e)=>{
    listBtn4();
    q4a.classList.add("active");
    j = 4;
    document.querySelector(".data4").innerHTML =49;
    for( i=1; i<=10; i++){
        if(question1[0] == 0){
            console.log("0");
        }else if(question1[0] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
    }
});
q4b.addEventListener("click", (e)=>{
    listBtn4();
    q4b.classList.add("active");
    j=4;
    document.querySelector(".data4").innerHTML =31;
    for( i=1; i<=10; i++){
        if(question1[1] == 0){
            console.log("0");
        }else if(question1[1] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
    }
});
q4c.addEventListener("click", (e)=>{
    listBtn4();
    q4c.classList.add("active");
    j=4;
    document.querySelector(".data4").innerHTML =13;
    for( i=1; i<=10; i++){
        if(question1[2] == 0){
            console.log("0");
        }else if(question1[2] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
    }
});
q4d.addEventListener("click", (e)=>{
    listBtn4();
    q4d.classList.add("active");
    j=4;
    document.querySelector(".data4").innerHTML =7;
    for( i=1; i<=10; i++){
        if(question1[3] == 0){
            console.log("0");
        }else if(question1[3] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
    }
});

// pour la question 5
var question1 = [75, 20, 10, 10 ]; 
let q5a = document.querySelector("#q5a");
let q5b = document.querySelector("#q5b");
let q5c = document.querySelector("#q5c");
let q5d = document.querySelector("#q5d");
function listBtn5(){
    q5b.classList.remove("active");
    q5a.classList.remove("active");
    q5c.classList.remove("active");
    q5d.classList.remove("active");
}
q5a.addEventListener("click", (e)=>{
    listBtn5();
    q5a.classList.add("active");
    j = 5;
    document.querySelector(".data5").innerHTML =75;
    for( i=1; i<=10; i++){
        if(question1[0] == 0){
            console.log("0");
        }else if(question1[0] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
    }
});
q5b.addEventListener("click", (e)=>{
    listBtn5();
    q5b.classList.add("active");
    j=5;
    document.querySelector(".data5").innerHTML =19;
    for( i=1; i<=10; i++){
        if(question1[1] == 0){
            console.log("0");
        }else if(question1[1] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
    }
});
q5c.addEventListener("click", (e)=>{
    listBtn5();
    q5c.classList.add("active");
    j=5;
    document.querySelector(".data5").innerHTML =5;
    for( i=1; i<=10; i++){
        if(question1[2] == 0){
            console.log("0");
        }else if(question1[2] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
    }
});
q5d.addEventListener("click", (e)=>{
    listBtn5();
    q5d.classList.add("active");
    j=5;
    document.querySelector(".data5").innerHTML =1;
    for( i=1; i<=10; i++){
        if(question1[3] == 0){
            console.log("0");
        }else if(question1[3] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
    }
});

// pour la question 6
var question1 = [75, 22, 10 ]; 
let q6a = document.querySelector("#q6a");
let q6b = document.querySelector("#q6b");
let q6c = document.querySelector("#q6c");
function listBtn6(){
    q6b.classList.remove("active");
    q6a.classList.remove("active");
    q6c.classList.remove("active");
}
q6a.addEventListener("click", (e)=>{
    listBtn6();
    q6a.classList.add("active");

    j = 6;
    document.querySelector(".data6").innerHTML =75;
    for( i=1; i<=10; i++){
        if(question1[0] == 0){
            console.log("0");
        }else if(question1[0] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
    }
});
q6b.addEventListener("click", (e)=>{
    listBtn6();
    q6b.classList.add("active");
    
    j=6;
    document.querySelector(".data6").innerHTML =22;
    for( i=1; i<=10; i++){
        if(question1[1] == 0){
            console.log("0");
        }else if(question1[1] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
    }
});
q6c.addEventListener("click", (e)=>{
    listBtn6();
    q6c.classList.add("active");
    
    j=6;
    document.querySelector(".data6").innerHTML = 3;
    for( i=1; i<=10; i++){
        if(question1[2] == 0){
            console.log("0");
        }else if(question1[2] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
    }
});

/*// pour la question 7
var question1 = [75, 22, 10 ]; 
let q6a = document.querySelector("#q6a");
let q6b = document.querySelector("#q6b");
let q6c = document.querySelector("#q6c");
function listBtn7(){
    q7b.classList.remove("active");
    q7a.classList.remove("active");
    q7c.classList.remove("active");
}
q6a.addEventListener("click", (e)=>{
    listBtn7();
    q7a.classList.add("active");

    j = 7;
    for( i=1; i<=10; i++){
        if(question1[0] == 0){
            console.log("0");
        }else if(question1[0] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
    }
});
q6b.addEventListener("click", (e)=>{
    listBtn7();
    q7b.classList.add("active");

    j=7;
    for( i=1; i<=10; i++){
        if(question1[1] == 0){
            console.log("0");
        }else if(question1[1] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
    }
});
q6c.addEventListener("click", (e)=>{
    listBtn7();
    q7c.classList.add("active");

    j=7;
    for( i=1; i<=10; i++){
        if(question1[2] == 0){
            console.log("0");
        }else if(question1[2] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
    }
});
*/
// pour la question 8
var question1 = ["65", 20, 10, 10 ]; 
let q8a = document.querySelector("#q8a");
let q8b = document.querySelector("#q8b");
let q8c = document.querySelector("#q8c");
let q8d = document.querySelector("#q8d");
function listBtn8(){
    q8b.classList.remove("active");
    q8a.classList.remove("active");
    q8c.classList.remove("active");
    q8d.classList.remove("active");
}
q8a.addEventListener("click", (e)=>{
    listBtn8();
    q8a.classList.add("active");

    j = 8;
    document.querySelector(".data8").innerHTML =65;
    for( i=1; i<=10; i++){
        if(question1[0] == 0){
            console.log("0");
        }else if(question1[0] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
    }
});
q8b.addEventListener("click", (e)=>{
    listBtn8();
    q8b.classList.add("active");
    
    j=8;
    document.querySelector(".data8").innerHTML =20;
    for( i=1; i<=10; i++){
        if(question1[1] == 0){
            console.log("0");
        }else if(question1[1] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
    }
});
q8c.addEventListener("click", (e)=>{
    listBtn8();
    q8c.classList.add("active");
    
    j=8;
    document.querySelector(".data8").innerHTML =11;
    for( i=1; i<=10; i++){
        if(question1[2] == 0){
            console.log("0");
        }else if(question1[2] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
    }
});
q8d.addEventListener("click", (e)=>{
    listBtn8();
    q8d.classList.add("active");
    
    j=8;
    document.querySelector(".data8").innerHTML =3;
    for( i=1; i<=10; i++){
        if(question1[3] == 0){
            console.log("0");
        }else if(question1[3] >= (i*10)){
            addColor();
        }else{
            removeColor();
        }
    }
});
